function ReloadBanners(){
//var aFrames = new Array('a17f9ff5','ac341ed4');
var aFrames = new Array('a0684d62');
var iCount = aFrames.length;
var iFrame;
for (var t = 0; t < iCount; t++){
	if (iFrame = document.getElementById(aFrames[t])){
		iFrame.src = iFrame.src;}
	}
	setTimer();
}

function setTimer(){
	setTimeout(ReloadBanners, 16000);
}

$(document).ready(function() {
    setTimer()
})